


import {Component, Vue} from "vue-property-decorator";
import {BASE_API_URL, PHONE_EXT, EventBus} from "@/config";
import Axios from "axios";
import {mixins} from "vue-class-component";

import {HollowDotsSpinner} from "epic-spinners";
import {Device} from "twilio-client";
import CommonMixin from "@/mixins/common.mixin";
import Popover from "vue-js-popover";
import {VueEditor} from "vue2-editor";
import saveAs from "save-as";
import striptags from "striptags";
import {BorrowerContact} from "@/models/borrower-contact.model";
import DisplayFile from "@/views/DisplayDocument.vue";
import ViewDocument from "@/views/ViewDocument.vue";
import stores from "@/store/store";
import Multiselect from "vue-multiselect";
import SelectBorrowerForPos from "@/views/SelectBorrowerForPos.vue";
import OverlayInput from "@/views/OverlayInput.vue";
import _ from "lodash";
import BootstrapVue from "bootstrap-vue";
import {ALLOWED_FILE_EXTENSIONS} from "@/constants";
import CheckCriticalFields from "@/views/CheckCriticalFields.vue";
import TextEditor from "@/views/TextEditor.vue";

Vue.use(BootstrapVue);

const store: any = stores;

Vue.use(Popover);

@Component({
  components: {
    HollowDotsSpinner,
    VueEditor,
    DisplayFile,
    Multiselect,
    SelectBorrowerForPos,
    ViewDocument,
    OverlayInput,
    CheckCriticalFields,
    TextEditor,
  }
})
export default class BorrowerDetail extends mixins(CommonMixin) {
  public allowedFileExtensions = ALLOWED_FILE_EXTENSIONS;
  public borrowerContact: any = new BorrowerContact();
  public borrower: any = null;
  public allDocuments = [];
  public requiredDocuments = [];
  public approvedDocuments = [];
  public brokerDisclosuresDocuments = [];
  public additionalDocuments = [];
  public approvedAdditionalDocuments = [];
  public batchToLenderAdditionalDocuments = [];
  public approvedBrokerDisclosuresDocuments = [];
  public showBrokerDisclosuresDocuments: boolean = false;
  public brokerDisclosuresDocumentsStatus = null;
  public displayFileType = "";
  public fileType = "";
  public fileToDisplay = "";
  public checkDocs = [];
  public fileIndex = null;
  public dotLoader = false;
  public callingStatus = null;
  public phoneNumber = null;
  public inProcess = false;
  public callInProgress = false;
  public comments: any = "";
  public count: any = 1;
  public review = false;
  public reviewFileIndex = null;
  public comment = "";
  public toUserId = "";
  public toUserType = "";
  public status = null;
  public checkBoxId = "";
  public commentSampleAttachment = [];
  public pipeLineHeaderData: any = {};
  public additionalTask: any = "";
  public customToolBar = [];
  public borrowerLoader = false;
  public reClassify: any = null;
  public reClassifyArray = [];
  public fileLoader = [];
  public byWemloStaff = false;
  public updateStatus = null;
  public statusId = "";
  public borrowerConditionValidation = true;
  public thirdPartyConditionValidation = true;
  public showExistBorrowerContact = false;
  public borrowerContacts = [];
  //FOR BATCH TO DOCUMENTS SHOWING===================================================================
  public batchToLenderDocuments = [];
  public contacts = [];
  public selectedContacts: any = [];
  public newContacts: any = "";
  public accountExecutiveDetails = [];
  public wemloStaff = [];
  public wemloStaffName = "";
  public accountExecutiveName = "";
  public notSelected = false;
  public wemloNumber = null;
  public isAction = false;
  public downloadingBrokerDisclosure: boolean = false;
  public isMismo: boolean = null;
  everyBrokerDisclosureIsSigned: boolean = false;
  lookingAtDocument = null;

  public searchablePendingConditions: any = null;
  public searchablePendingSubmission: any = null;
  public searchableSubmittedToLender: any = null;
  public emptyMessage = false;
  public fileForDelete = {};
  public fileInput = null;
  public visibleInputId:string = null;
  public enterTargetRow:object = null;
  public editModeForTitle: boolean = false;
  public contactTypeIsTitle:boolean = false;
  
  //==============================================
  //To Add Pending Conditions
  public borrowerCondition = [
    {
      categoryName: null,
      samples: []
    }
  ];
  public thirdPartyCondition = [
    {
      categoryName: null,
      categoryType: null,
      otherCategoryType: null,
      samples: []
    }
  ];
  public submitCondition = false;
  //==============================================
  public toggleData = true;
  public openBorrowerSelectionModal = false;
  public loanTxnId = null;
  public borrowerName = "";

  get userType() {
    return this.$store.state.sessionObject.type;
  }

//Playing learn more video
  private playWeLearnVideo() {
    EventBus.$emit('openWeLearn', {
      taskName: 'Pending Conditions',
      videoUrl: 'https://wemlo.s3.amazonaws.com/Common/taskdemo/ReviewingDocuments.mp4'
    })
  }

  public async getAdditionalStatus() {
    let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/getAdditionalStatus",
        {loanTxnId: this.borrower.pipelineData.loanTxnId});
    this.additionalTask = response.data;
  }

  public data() {
    return {
      customToolBar: [
        ["bold", "italic", "underline"],
        [{list: "ordered"}, {list: "bullet"}]
      ]
    };
  }

  // Pending conditions:
  public get filteredBrokerDisclosuresDocuments() {
    return this.getFilteredDocs(this.brokerDisclosuresDocuments, this.searchablePendingConditions)
  }

  public get filteredRequiredDocuments() {
    return this.getFilteredDocs(this.requiredDocuments, this.searchablePendingConditions)
  }

  public get filteredAdditionalDocuments() {
    return this.getFilteredDocs(this.additionalDocuments, this.searchablePendingConditions)
  }

  // Pending Submission
  public get filteredApprovedDocuments() {
    return this.getFilteredDocs(this.approvedDocuments, this.searchablePendingSubmission)
  }

  public get filteredApprovedAdditionalDocuments() {
    return this.getFilteredDocs(this.approvedAdditionalDocuments, this.searchablePendingSubmission)
  }

  public get filteredApprovedBrokerDisclosuresDocuments() {
    return this.getFilteredDocs(this.approvedBrokerDisclosuresDocuments, this.searchablePendingSubmission)
  }

  // Pending Submission
  public get filteredBatchToLenderDocuments() {
    return this.getFilteredDocs(this.batchToLenderDocuments, this.searchableSubmittedToLender)
  }

  public get filteredBatchToLenderAdditionalDocuments() {
    return this.getFilteredDocs(this.batchToLenderAdditionalDocuments, this.searchableSubmittedToLender)
  }

  public getFilteredDocs(docs, searchable) {
    return searchable ? docs.filter(doc => doc.categoryName.toLowerCase().includes(searchable.toLowerCase())) : docs
  }

  public async save(data, checkBox, status, id) {
    try {
      this.statusId = id;
      this.updateStatus = status;
      this.$modal.show("confirmToAddStatus");
      this.status = data;
      this.checkBoxId = checkBox;
    } catch (error) {
      console.log(error);
    }
  }

  public cancel() {
    this.status = "";
    this.$modal.hide("makeAddStatus");
    this.$modal.hide("confirmToAddStatus");
    let x: any = document.getElementById(this.checkBoxId);
    x.checked = false;
    x.disabled = false;
  }

  public async addStatus(status) {
    try {
      let response: any = "";
      let body = {};
      let x: any = document.getElementById(this.checkBoxId);
      let z: any = document.getElementById("status");
      z.disabled = true;

      x.checked = true;
      x.disabled = true;
      if (status) {
        if (this.updateStatus == "Active") {
          body = {
            additionalStatus: this.status,
            id: this.statusId
          };
          response = await Axios.post(
              BASE_API_URL + "wemloprocessor/saveStatus",
              {
                body: body,
                loanTxnId: this.borrower.pipelineData.loanTxnId,
                identifier: "Active"
              });
        } else {
          body = {
            active: false,
            statusCompleted: true,
            taskId: null,
            statusId: null,
            count: 0,
            timeline: true,
            additionalStatus: this.status,
            updatedOn: new Date()
          };
          response = await Axios.post(
              BASE_API_URL + "wemloprocessor/saveStatus",
              {
                body: body,
                loanTxnId: this.borrower.pipelineData.loanTxnId,
                identifier: "addStatus"
              });
        }
        if (response.status == 200) {
          this.$snotify.success("You have sucessfully Updated your task");
          this.status = "";
          this.getAdditionalStatus();
        } else {
          this.$snotify.error("Sorry Try Again");
        }

        this.$modal.hide("confirmToAddStatus");
      } else {
        this.status = "";
        x.checked = false;
        x.disabled = false;
        this.$modal.hide("confirmToAddStatus");
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async saveStatus() {
    let formValid = await this.$validator.validateAll();
    if (!formValid) {
      return;
    }
    this.$modal.hide("makeAddStatus");
    let body = {
      active: false,
      statusCompleted: false,
      taskId: null,
      statusId: null,
      count: 0,
      timeline: false,
      additionalStatus: this.status,
      updatedOn: new Date()
    };
    let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/saveStatus",
        {
          body: body,
          loanTxnId: this.borrower.pipelineData.loanTxnId,
          identifier: "saveStatus"
        });
    if (response.status == 200) {
      this.$snotify.success("You have sucessfully added your task");
      this.status = "";
      this.getAdditionalStatus();
    } else {
      this.$snotify.error("Sorry Try Again");
    }
  }

  public openModal() {
    this.$modal.show("makeAddStatus");
  }

  public closeModal() {
    this.status = "";
    this.$modal.hide("makeAddStatus");
  }

  public async messageContacts() {
    try {
      let response = await Axios.post(
          BASE_API_URL + "common/messageContacts",

          {
            userType: "Wemlo Processor",
            id: this.borrower.pipelineData.loanTxnId,
            userId: this.$userId
          });
      this.accountExecutiveDetails =
          response.data.contacts.accountExecutiveDetails;
      this.wemloStaff = response.data.contacts.wemloStaff;
    } catch (error) {
      console.log(error);
    }
  }

  public async addNewContact() {
    try {
      this.editModeForTitle = false;
      this.inProcess = true;
      let validate = await this.$validator.validateAll();
      if (!validate) {
        this.inProcess = false;
        return;
      }
      this.borrowerContact["loanTxnId"] = this.borrower.pipelineData.loanTxnId;
      this.borrowerContact["addedBy"] = this.$userId;
      let response = await Axios.post(
          BASE_API_URL + "wemloprocessor/addNewContact",
          this.borrowerContact);
      if (response.status == 200) {
        this.inProcess = false;
        this.isAction = false;
        await this.fetchBorrowerContacts();
        this.getContactList();
        this.borrowerContact = {};
        if (response.data.contacts) {
          await this.messageContacts();
          this.$snotify.success("Sucessfully Added");
          this.$modal.hide("addContact");
          this.newContacts = response.data.contacts;
          let addNewContact = {
            name: this.newContacts.firstName + " " + this.newContacts.lastName,
            toUserId: this.newContacts._id,
            toUserType: "borrower-contact",
            phoneNumber: this.newContacts.phone
          };
          this.selectedContacts.push(addNewContact);
        } else {
          this.newContacts = response.data.details;
          this.selectedContacts.forEach(e => {
            if (e.toUserId == this.newContacts._id) {
              if (this.newContacts.type != "Title") {
                e.name =
                    this.newContacts.firstName + " " + this.newContacts.lastName;
              } else {
                e.name = this.newContacts.companyName;
              }
              e.phoneNumber = this.newContacts.phone;
            }
          });
          this.$snotify.success("Sucessfully Updated");
        }
        this.showExistBorrowerContact = false;
        this.toggleData = true;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async cancelForAddContact() {
    this.$modal.hide("addContact");
    this.borrowerContact = {};
    this.inProcess = false;
    this.isAction = false;
    this.showExistBorrowerContact = false;
    this.toggleData = true;
  }

  //TO SEND MESSAGE AND EMAIL TO BORROWER,BROKER AND MLO FROM WEMLO PROCESSOR

  public async addMessage() {
    if (this.selectedContacts.length == 0) {
      this.notSelected = true;
      return;
    }
    if (!this.message) {
      this.$snotify.error("Message body cannot be empty.");
      return;
    }
    this.sendMessage({
      loanTxnId: this.borrower.pipelineData.loanTxnId,
      contacts: this.selectedContacts,
      borrowerName: this.getPrimaryBorrowerName(
          this.borrower.pipelineData.borrowerInfo,
          this.borrower.pipelineData.primaryBorrowerIndex,
          "pipelineHeader"
      )
    });
    this.selectedContacts = [];
  }

  public async moveToLeadPopup(loanTxnId) {
    this.loanTxnId = loanTxnId;
    this.borrowerName =
        this.borrower.pipelineData.borrowerInfo[0].firstName +
        " " +
        this.borrower.pipelineData.borrowerInfo[0].lastName;
    this.$modal.show("deleteLeadConfirmation");
  }

  // To change the status of moveToLead when clicked on the button MoveToLead on processor side
  //so that after inprocessing loan can move to leads
  public async moveToLead() {
    try {
      this.$modal.hide("deleteLeadConfirmation");
      this.$store.state.wemloLoader = true;
      let borrowerName =
          this.borrower.pipelineData.borrowerInfo[0].firstName +
          " " +
          this.borrower.pipelineData.borrowerInfo[0].lastName;
      let response = await Axios.post(
          BASE_API_URL + "broker/moveToLead",
          {
            loanTxnId: this.loanTxnId,
            borrowerName: borrowerName,
            userId: this.$userId,
            userType: this.$userType
          });
      this.$store.state.wemloLoader = false;
      // this.$snotify.success(borrowerName + " move to leads ");
      this.$snotify.success(borrowerName + " move to leads ");
      this.$router.push("/dashboard");
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error);
    }
  }

  public getDocumentName(doc) {
    // TODO FIXME - make utility function, shared with wemlo-processor/BorrowerDetail, broker/Pipeline.vue, DisplayDocument.vue, etc.
    if (doc && doc.borrowerName) {
      return (`${doc.borrowerName} - ${doc.annotatedCategoryName || doc.categoryName}`);
    } else if (doc && doc.categoryName) {
      return (`${doc.annotatedCategoryName || doc.categoryName}`);
    } else {
      return "";
    }
  }

  public async getBorrowerLoanDetails() {
    this.borrowerLoader = true;
    try {
      let response = await Axios.post(
          BASE_API_URL + "broker/getBorrowerLoanDetails",
          {
            loanTxnId: this.$route.query.id,
            type: this.userType
          });
      this.pipeLineHeaderData = this.assignPipelineHeaderData(
          response.data.pipelineData,
          true
      );
      this.borrower = response.data;
      if (response.data.inActiveArchived) {
        this.$snotify.success(response.data.message);
        this.$router.push(this.$getCurrentUserTypePath("dashboard"));
      }
      this.phoneNumber =
          PHONE_EXT +
          this.borrower.pipelineData.borrowerInfo[0].cellPhone.replace(
              /[()-\s]/g,
              ""
          );
      await this.getDocuments(this.borrower.borrowerData.documents.documents);
      this.borrowerLoader = false;
    } catch (error) {
      this.borrowerLoader = false;
      console.log(error);
    }
  }

  public getDocuments(documents) {
    this.allDocuments = [];
    this.approvedDocuments = [];
    this.requiredDocuments = [];
    this.allDocuments = documents;
    this.allDocuments.forEach((data, index) => {
      if (data.documentStatus != "Not Applicable") {
        if (data.fileHistory.length == 0) {
          this.requiredDocuments.push(data);
        } else {
          if (
              data.fileHistory[data.fileHistory.length - 1].status != "Approved"
          ) {
            this.requiredDocuments.push(data);
          } else {
            this.approvedDocuments.push(data);
            this.checkDocs.push(false);
          }
        }
      }
    });
    this.setBatchToLenderDocs();
    // this.setReClassify();
  }

  public setBatchToLenderDocs() {
    try {
      this.batchToLenderDocuments = [];
      this.approvedDocuments.forEach((doc, i, array) => {
        if (doc.hasOwnProperty("batchToLender")) {
          this.batchToLenderDocuments.push(doc);
        }
      });
      let arr = this.approvedDocuments;
      this.batchToLenderDocuments.forEach(d => {
        arr.forEach((v, i) => {
          if (v._id == d._id) {
            arr.splice(i, 1);
            this.checkDocs.splice(0, 1);
          }
        });
      });

      this.approvedDocuments = arr;
    } catch (error) {
      console.log(error);
    }
  }

  public setReClassify() {
    this.reClassifyArray = [];
    this.requiredDocuments.forEach(f => {
      let obj = {
        categoryName: f.categoryName,
        categoryType: f.categoryType
      };
      this.reClassifyArray.push(obj);
    });
  }

  public async downlaodDocsPrompt() {
    try {
      let countFalse = false;
      let arr = [];
      let setBatchToLenderDocs = [];
      let additionalDocuments = this.approvedAdditionalDocuments.filter(document => document.checked).map(document => document._id);

      this.checkDocs.forEach((check, index) => {
        if (check) {
          countFalse = check;
          this.approvedDocuments[index].fileHistory[
          this.approvedDocuments[index].fileHistory.length - 1
              ].files.forEach(file => {
            arr.push({
              path: file.path,
              name: file.originalName
            });
          });
          setBatchToLenderDocs.push(this.approvedDocuments[index]._id);
        }
      });
      if (!countFalse && !additionalDocuments.length) {
        this.$snotify.clear();
        this.$snotify.error("Please select atleast one option");
        return;
      }

      let data = {
        loanTxnId: this.$route.query.id,
        docs: [
          ...setBatchToLenderDocs,
          ...this.approvedAdditionalDocuments.filter(document => document.checked).map(document => document._id)
        ]
      };

      arr = [
        ...arr,
        ...this.approvedAdditionalDocuments.filter(document => document.checked).map(document => {
          const lastFile = _.last(document.fileHistory);
          return {
            name: _.get(_.last(lastFile['files']), 'originalName'),
            path: _.get(_.last(lastFile['files']), 'path')
          }
        })
      ];

      // Sending the data to mixins
      await this.$refs.df["downloadCurrentReviewDocument"](
          arr,
          data,
          true,
          "batchToLender"
      );
      await this.getBorrowerLoanDetails();
      await this.getWesignRequiredDocuments();

      this.approvedAdditionalDocuments.forEach(document => {
        document.checked = false;
      });

      this.checkDocs.forEach((c, i) => {
        this.checkDocs[i] = false;
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async reviewFiles(check) {
    try {
      if (check == "Rejected") {
        let formValid = await this.$validator.validate("comment");
        if (!formValid || this.comment == "") {
          return;
        }
      }
      let commentIdentifier;
      this.requiredDocuments[this.reviewFileIndex].fileHistory[
      this.requiredDocuments[this.reviewFileIndex].fileHistory.length - 1
          ].status = check;
      this.requiredDocuments[this.reviewFileIndex].fileHistory[
      this.requiredDocuments[this.reviewFileIndex].fileHistory.length - 1
          ].actionBy = this.$store.state.sessionObject.userInfo._id;
      this.requiredDocuments[this.reviewFileIndex].fileHistory[
      this.requiredDocuments[this.reviewFileIndex].fileHistory.length - 1
          ].actionOn = new Date();
      this.requiredDocuments[this.reviewFileIndex].updatedOn = new Date();
      this.allDocuments.forEach((data, i) => {
        if (data._id == this.requiredDocuments[this.reviewFileIndex]._id) {
          this.allDocuments[i] = this.requiredDocuments[this.reviewFileIndex];
          commentIdentifier = i;
        }
      });
      let dataObj: any = {
        loanTxnId: this.$route.query.id,
        documents: this.allDocuments,
        commentIdentifier: commentIdentifier
      };
      if (this.comment != "") {
        dataObj.comment = this.comment;
      }
      if (this.commentSampleAttachment.length > 0) {
        dataObj.attachment = this.commentSampleAttachment;
      }
      if (this.reClassify != null) {
        dataObj.reClassify = this.reClassify;
      }
      this.$store.dispatch("emptyLocalFiles");
      let response = await Axios.post(
          BASE_API_URL + "wemloprocessor/reviewDocument",
          dataObj);
      this.review = false;
      this.getBorrowerLoanDetails();
      if (response.data.message) {
        this.$snotify.success("Document review successfully");
      }
      this.comment = "";
      this.commentSampleAttachment = [];
      this.reClassify = null;
      this.$modal.hide("displayFile");
    } catch (error) {
      console.log(error);
    }
  }

  public showThreeFileHistory(fileHistory) {
    let length = fileHistory.length;
    if (length > 3) {
      return fileHistory.slice(length - 3, length);
    }
    return fileHistory;
  }

  public async uploadSample(event) {
    let loader = this.$loading.show();
    try {
      if (event.target.files.length == 0) {
        loader.hide();
        return;
      }
      let files = [];
      Object.values(event.target.files).forEach(f => {
        files.push(f);
      });
      let path = "Borrower/" + this.$route.query.id + "/LoanDocuments/";
      let formData = new FormData();
      formData.append("path", JSON.stringify(path));
      files.forEach(f => {
        formData.append("file", f);
      });
      let response = await Axios.post(
          BASE_API_URL + "wemloprocessor/getFileUploadedPath",
          formData);
      this.commentSampleAttachment = [];
      let localFiles = [];
      response.data.forEach(element => {
        this.commentSampleAttachment.push({path: element.path});
        localFiles.push({path: element.path});
      });
      this.$store.dispatch("setLocalFiles", localFiles);
      loader.hide();
    } catch (error) {
      loader.hide();
      console.log(error);
    }
  }

  public ifDocumentNotLoad(time: number) {
    this.dotLoader = true;
    try {
      setTimeout(() => {
        let checkLoader = document.getElementById("checkLoader");
        if (checkLoader != null) {
          let iframe: any = document.getElementById("checkElement");
          if (iframe.localName == "iframe") {
            iframe.src =
                "https://docs.google.com/gview?url=" +
                this.fileToDisplay +
                "&embedded=true";
          } else {
            iframe.src = this.fileToDisplay;
          }
          this.ifDocumentNotLoad(time + 1000);
        }
      }, time);
    } catch (error) {
      console.log(error);
      this.dotLoader = false;
    }
  }

  get wemloStaffId() {
    return this.$store.state.sessionObject.userId;
  }

  public checkUploadedBy(data) {
    data.forEach(element => {
      if (element.uploadedBy == this.wemloStaffId) {
        this.byWemloStaff = true;
      } else {
        this.byWemloStaff = false;
      }
    });
  }

  displayDocument(document) {
    if (!document) {
      return;
    }

    this.lookingAtDocument = document;
    this.$modal.show('viewDocumentModal');
  }

  closingViewDocument() {
    this.$modal.hide('viewDocumentModal');
    this.lookingAtDocument = null;
  }

  async reopenViewDocument() {
    this.$modal.hide('viewDocumentModal');
    await this.getWesignRequiredDocuments();

    const currentViewingDocument = _.get(this.lookingAtDocument, '_id', null);
    const disclosures = [...this.approvedBrokerDisclosuresDocuments, ...this.brokerDisclosuresDocuments];
    const document = disclosures.find(disclosure => _.get(disclosure, '_id', undefined) === currentViewingDocument);

    if (!currentViewingDocument || !document) {
      this.lookingAtDocument = null;
      return;
    }

    this.lookingAtDocument = document;
    this.$modal.show('viewDocumentModal');
  }

  public displayDocuments(fileIndex, documentIndex, check, origin) {
    try {
      const commentPath = _.get(this, `[${origin}][${documentIndex}].fileHistory[${fileIndex}].commentPath`, null);
      const commentData = _.get(this, `borrower.borrowerData.comment[${commentPath}]`, null);

      let comment = null;
      let commentAttachs = null;

      if (commentData) {
        comment = commentData.comment;
        commentAttachs = commentData.attachments.length > 0 ? commentData.attachments : null;
      }

      const files = _.get(this, `[${origin}][${documentIndex}].fileHistory[${fileIndex}].files`, []);

      this.$refs.df["setInitialValuesForAttachmentsAndComments"](
          comment,
          files,
          commentAttachs,
          null,
          _.get(this, `[${origin}][${documentIndex}].fileHistory[${fileIndex}]`)
      );
    } catch (error) {
      console.log(error);
    }
  }

  /**************************************************************************************************
   *                                   TO DELETE THE FILE                                            *
   **************************************************************************************************/
  public deleteFile(file) {
    try {
      let delFile = file.files;
      let del = file.deleteData;
      let fromDelete = JSON.parse(JSON.stringify(this[del.main][del.index]));
      if (fromDelete.samplesCount > 1) {
        fromDelete.samplesCount--;
      } else {
        delete fromDelete.samplesCount;
      }
      fromDelete.samples = fromDelete.samples.filter(
          s => s.path != delFile
      );
      this.$set(
          this[del.main],
          del.index,
          JSON.parse(JSON.stringify(fromDelete))
      );
    } catch (error) {
      console.log(error);
    }
  }

  public deleteDoc(from) {
    try {
      this[from.deleteData].splice(from.index, 1);
    } catch (error) {
      console.log(error);
    }
  }

  public showDisplayDocument(files, deleteButton, fromData) {
    this.$refs.df["setInitialValuesForShowingSamplesWithDeleteButton"](
        files,
        deleteButton,
        fromData
    );
  }

  //================================================
  public toggleConditionModal(check) {
    //To open the Add (Pending Condition) Modal or to close
    try {
      if (check) {
        if (this.borrowerCondition.length == 0) {
          this.addBorrowerCondition();
        }
        if (this.thirdPartyCondition.length == 0) {
          this.addThirdPartyCondition();
        }
        this.$modal.show("addPendingCondition");
      } else if (!check) {
        this.$modal.hide("addPendingCondition");
        this.submitCondition = false;
        this.borrowerCondition = [];
        this.thirdPartyCondition = [];
        let data = JSON.parse(localStorage["state.WeMLO"]);
        if (data.removeLocalFiles.length > 0) {
          store.dispatch("removeLocalFiles", data.removeLocalFiles);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  //===============================================
  public removeBorrowerCondition(index) {
    //Removing BorrowerCondition basis of index
    try {
      this.borrowerCondition.splice(index, 1);
    } catch (error) {
      console.log(error);
    }
  }

  //===============================================
  public removeThirdPartyCondition(index) {
    //Removing Third Party Condition basis of index
    try {
      this.thirdPartyCondition.splice(index, 1);
    } catch (error) {
      console.log(error);
    }
  }

  //===============================================
  public addBorrowerCondition() {
    //Adding a Borrower Condtion on clicking in plus button
    try {
      let obj = {
        categoryName: null,
        samples: [],
        samplesCount: null
      };
      this.borrowerCondition.push(obj);
    } catch (error) {
      console.log(error);
    }
  }

  //===============================================
  public addThirdPartyCondition() {
    //Adding a Third Party Condtion on clicking in plus button
    try {
      let obj = {
        categoryName: null,
        categoryType: null,
        samples: [],
        samplesCount: null,
        otherCategoryType: null
      };
      this.thirdPartyCondition.push(obj);
    } catch (error) {
      console.log(error);
    }
  }

  //===============================================
  public async addPendingCondition() {
    //Sending data to server to save it
    try {
      this.submitCondition = true;
      let formValid = await this.$validator.validateAll();
      if (!formValid) {
        this.submitCondition = false;
        return;
      }
      this.borrowerConditionValidation = true;
      this.thirdPartyConditionValidation = true;
      let thirdPartyCondition = JSON.parse(
          JSON.stringify(this.thirdPartyCondition)
      );
      thirdPartyCondition.forEach(value => {
        if (value.categoryType == "Other") {
          value.categoryType = value.otherCategoryType;
        }
        delete value.otherCategoryType;
      });
      this.$store.dispatch("emptyLocalFiles");
      let obj = {
        loanTxnId: this.$route.query.id,
        borrowerCondition: this.borrowerCondition,
        thirdPartyCondition: thirdPartyCondition
      };
      let response = await Axios.post(
          BASE_API_URL + "wemloprocessor/addPendingCondition",
          obj);
      await this.getBorrowerLoanDetails();
      this.$snotify.success(response.data.message);
      this.toggleConditionModal(false);
    } catch (error) {
      console.log(error);
    }
  }

  //================================================

  public async getWemloNumber() {
    let response = await Axios.post(
        BASE_API_URL + "communication/getWemloNumber",
        {
          userId: this.$store.state.sessionObject.userInfo.userId
        });
    this.wemloNumber = response.data.wemloNumber;
  }

  public async callToCustomer() {
    try {
      if (this.selectedContacts.length == 0) {
        this.notSelected = true;
        return;
      }
      if (!this.selectedContacts[0].phoneNumber) {
        this.$snotify.error(
            "Call to " +
            this.selectedContacts[0].name +
            " is not possible because they do not have phone number"
        );
        return;
      }
      this.callingStatus = "Connecting...";
      let tempCallId = Math.floor(Math.random() * 100000000000000000);
      var params = {
        wemloNumber: this.wemloNumber,
        tempCallId: tempCallId,
        phoneNumber: this.selectedContacts[0].phoneNumber,
        fromUserId: this.$userId,
        fromType: "Wemlo Processor",
        toUserType: this.selectedContacts[0].toUserType,
        toUserId: this.selectedContacts[0].toUserId,
        loanTxnId: this.borrower.pipelineData.loanTxnId
      };
      await Device.connect(params);
      await Device.once("connect", connection => {
        this.callInProgress = true;
        this.callingStatus = "In call with " + connection.message.phoneNumber;
      });
      await Device.once("error", () => {
        this.callingStatus = "Cannot connect now.";
        this.callInProgress = false;
      });
      await Device.once("disconnect", async () => {
        this.callingStatus = "Disconnected";
        this.callInProgress = false;
        setTimeout(() => {
          this.callingStatus = "Ready";
        }, 200);
        await this.saveAndGetCallDetails(tempCallId);
      });
    } catch (error) {
      console.error(error);
    }
  }

  public getCallButtonTitle() {
    if (this.callingStatus == "Cannot connect now.") {
      return "Call cannot be connected now.Please try again later.";
    } else if (this.callingStatus == "Connecting...") {
      return "Call in Progress.";
    } else if (this.selectedContacts.length > 1) {
      return "Please select only one user to enable call button.";
    } else {
      return null;
    }
  }

  public async saveAndGetCallDetails(tempCallId) {
    try {
      let response = await Axios.post(
          BASE_API_URL + "communication/saveAndGetCallDetails",
          {
            tempCallId: tempCallId,
            fromUserId: this.$userId,
            fromType: "Wemlo Processor",
            toUserType: this.selectedContacts[0].toUserType,
            toUserId: this.selectedContacts[0].toUserId,
            loanTxnId: this.borrower.pipelineData.loanTxnId
          });
    } catch (error) {
      console.log(error.response);
    }
  }

  public async disconnectCall() {
    try {
      Device.disconnectAll();
    } catch (error) {
      console.log(error.response);
    }
  }

  public async sendTextMessage() {
    try {
      if (this.selectedContacts.length == 0) {
        this.notSelected = true;
        return;
      }
      if (!this.wemloNumber) {
        this.$snotify.error(
            "You have not been assigned wemlo number yet.Please contact admin."
        );
        return;
      }
      if (this.message == null) {
        this.$snotify.error("Message body cannot be empty.");
        return;
      }
      if (this.file.length > 0) {
        this.file = [];
        this.file = JSON.parse(JSON.stringify(this.file));
        this.$snotify.error("Files cannot be sent with text message.");
        return;
      }
      this.inProcess = true;

      let msg = striptags(this.message, [], " "); //To strip html into normal text.
      msg = msg.replace(/\s\s+/g, " "); //To remove multiple white spaces as above function create multiple white spaces in case of <ol> and <ul> tags.

      let toUsers = [];
      let phoneNumberNotExist = [];
      this.selectedContacts.forEach(e => {
        if (e.phoneNumber) {
          toUsers.push({
            number: e.phoneNumber,
            userId: e.toUserId,
            type: e.toUserType
          });
        } else {
          phoneNumberNotExist.push(e.name);
        }
      });
      let response = await Axios.post(
          BASE_API_URL + "communication/sendTextMessage",
          {
            message: msg,
            fromUserId: this.$userId,
            toUsers: toUsers,
            fromType: "Wemlo Processor",
            loanTxnId: this.borrower.pipelineData.loanTxnId,
            wemloNumber: this.wemloNumber,
            callingFrom: "wemloPlatform"
          });
      this.message = null;
      this.selectedContacts = [];
      this.$snotify.success("Message successfully sent.");
      if (phoneNumberNotExist.length > 0) {
        let errorMsg = phoneNumberNotExist.join(",");
        this.$snotify.error(
            "Message have not sent to " +
            errorMsg +
            " because they do not have phone number"
        );
      }
      this.inProcess = false;
    } catch (error) {
      this.inProcess = false;
    }
  }

  public async closeMakeCallModal() {
    this.$modal.hide("makeCallModal");
    await this.disconnectCall();
  }

  /**
   * uploading pending condition documents
   */
  public async uploadFile(event, id, index, collection) {
    try {
      if (collection !== 'additionalDocuments') {
        this.$set(this.fileLoader, index, true);
      }

      if (event.target.files.lloanTxnIdength == 0) {
        return;
      }
      let formData = new FormData();
      Object.keys(event.target.files).forEach((e, i) => {
        formData.append("file", event.target.files[i]);
      });
      formData.append("id", id);
      formData.append("loanTxnId", this.borrower.pipelineData.loanTxnId);
      formData.append("userId", this.$userId);
      await Axios.post(
          BASE_API_URL + "pos/uploadLoanDocument",
          formData);
      this.$set(this.fileLoader, index, false);
      this.$snotify.success("Document Uploaded Successfully");
      this.getBorrowerLoanDetails();

      if (collection === 'additionalDocuments') {
        await this.getWesignRequiredDocuments();
      }
    } catch (error) {
      this.$set(this.fileLoader, index, false);
      console.log(error);
    }
  }

  public clickUploadButton(event) {
    try {
      const ref = this.$refs[`upload_document_${event.id}`][0];
      ref.click();
    } catch (e) {
      console.error(e);
    }
  }

  public async uploadPendingConditionSample(event, origin, index) {
    let loader = this.$loading.show();
    try {
      if (event.target.files.length == 0) {
        loader.hide();
        return;
      }
      this[origin][index].samplesCount = null;
      this[origin][index].samplesCount = event.target.files.length;
      let files = [];
      Object.values(event.target.files).forEach(f => {
        files.push(f);
      });
      let path = "Borrower/" + this.$route.query.id + "/LoanDocuments/";
      let formData = new FormData();
      formData.append("path", JSON.stringify(path));
      files.forEach(f => {
        formData.append("file", f);
      });
      let response = await Axios.post(
          BASE_API_URL + "wemloprocessor/getFileUploadedPath",
          formData);
      this[origin][index].samples = [];
      let localFiles = [];
      response.data.forEach(element => {
        this[origin][index].samples.push({
          path: element.path,
          originalName: element.originalName
        });
        localFiles.push({path: element.path});
      });
      this.$store.dispatch("setLocalFiles", localFiles);
      this[origin] = JSON.parse(JSON.stringify(this[origin]));
      loader.hide();
    } catch (error) {
      loader.hide();
      console.log(error);
    }
  }

  public showPendingConditions(name, samples, history) {
    try {
      this.$refs.df["setInitialValuesForShowingSamples"](name, samples, null);
    } catch (error) {
      console.log(error);
    }
  }

  get twilioActiveStatus() {
    return this.$store.state.twilioActiveStatus;
  }

  public showAttachedFiles(index) {
    this.$modal.show('toSeeAttachment', {
      type: 'outgoing',
      file: this.file,
      currentFileIndex: index,
    });
  }

  public borrowerValidation() {
    // check borrowerCondition Validatiion Required or not
    for (let i = 0; i < this.borrowerCondition.length; i++) {
      if (
          this.borrowerCondition[i].categoryName == "" ||
          this.borrowerCondition[i].categoryName == null
      ) {
        this.borrowerConditionValidation = true;
      }
      this.thirdPartyConditionValidation = false;
    }
    if (!this.thirdPartyConditionValidation) {
      for (let i = 0; i < this.thirdPartyCondition.length; i++) {
        if (
            this.thirdPartyCondition[i].categoryName != null ||
            this.thirdPartyCondition[i].categoryType != null
        ) {
          this.thirdPartyConditionValidation = true;
        }
      }
    }
  }

  public thirdPartyValidation() {
    // check thirdPartyCondition Validatiion Required or not
    for (let i = 0; i < this.thirdPartyCondition.length; i++) {
      if (
          this.thirdPartyCondition[i].categoryName == "" ||
          this.thirdPartyCondition[i].categoryType == null
      ) {
        this.thirdPartyConditionValidation = true;
      }
      this.borrowerConditionValidation = false;
    }
    if (!this.borrowerConditionValidation) {
      for (let i = 0; i < this.addPendingCondition.length; i++) {
        if (this.borrowerCondition[i].categoryName != null) {
          this.borrowerConditionValidation = true;
        }
      }
    }
  }

  public resetCondition(origin, data, index) {
    if (origin == "borrowerCondition" && (data == "" || data == null)) {
      this[origin][index].categoryName = null;
      this[origin][index].samples = [];
      this[origin][index].samplesCount = 0;
    }
    if (
        origin == "thirdPartyCondition" &&
        data == null &&
        (this[origin][index].categoryName == "" ||
            this[origin][index].categoryName == null)
    ) {
      this[origin][index].categoryName = null;
      this[origin][index].categoryType = null;
      this[origin][index].samples = [];
      this[origin][index].samplesCount = 0;
    }
  }

  public updateSelected() {
    this.selectedContacts.forEach(selectedContacts => {
      if (selectedContacts.name == "addLender") {
        this.selectedContacts = this.selectedContacts.filter(
            e => e.name != "addLender"
        );
        this.accountExecutiveDetails.forEach(list => {
          list.checked = !!this.selectedContacts.find(a => a.toUserId == list._id);
        });
        this.$modal.show("accountExecutive");
      } else if (selectedContacts.name == "addWemloStaff") {
        this.selectedContacts = this.selectedContacts.filter(
            e => e.name != "addWemloStaff"
        );
        this.wemloStaff.forEach(list => {
          list.checked = !!this.selectedContacts.find(a => a.toUserId == list.userId);
        });
        this.$modal.show("wemloStaffList");
      } else if (selectedContacts.name == "addContact") {
        this.selectedContacts = this.selectedContacts.filter(
            e => e.name != "addContact"
        );
        this.borrowerContacts.forEach(list => {
          list.checked = !!this.selectedContacts.find(a => a.toUserId == list._id);
        });
        this.fetchBorrowerContacts();
        this.$modal.show("addContact");
      }
    });
  }

  public async getContactList() {
    try {
      this.contacts = [];
      let borrower = this.borrower.pipelineData.borrowerInfo.map(el => {
        return {
          name: el.firstName + " " + el.lastName,
          toUserId: el.userId,
          toUserType: "Borrower",
          phoneNumber: el.cellPhone
        };
      });
      if (
          this.borrower.pipelineData.brokerId !=
          this.borrower.pipelineData.addedBy
      ) {
        let mlo = {
          name: `Mlo - ${this.borrower.pipelineData.mloName}`,
          toUserId: this.borrower.pipelineData.addedBy,
          toUserType: "MLO",
          phoneNumber: this.borrower.pipelineData.mloPhone
              ? PHONE_EXT +
              this.borrower.pipelineData.mloPhone.replace(/[()-\s]/g, "")
              : null
        };
        this.contacts.push(mlo);
      } else {
        let broker = {
          name: `Broker - ${this.borrower.pipelineData.mloName}`,
          toUserId: this.borrower.pipelineData.brokerId,
          toUserType: "Broker",
          phoneNumber: this.borrower.pipelineData.brokerPhone
              ? PHONE_EXT +
              this.borrower.pipelineData.brokerPhone.replace(/[()-\s]/g, "")
              : null
        };
        this.contacts.push(broker);
      }
      let Lender = {
        name: `addLender`
      };
      let wemloStaff = {
        name: `addWemloStaff`
      };
      let addContact = {
        name: `addContact`
      };
      this.contacts = this.contacts.concat(borrower);
      this.contacts.push(Lender);
      this.contacts.push(wemloStaff);
      this.contacts.push(addContact);
    } catch (error) {
      console.log(error);
    }
  }

  //select a single processor in wemloStaff list
  public showDataForWemloStaff(index, userId) {
    try {
      let phoneNumber;

      if (this.wemloStaff[index].wemloNumber != null) {
        phoneNumber = this.wemloStaff[index].wemloNumber.replace(/[()-\s]/g, "");
      } else {
        phoneNumber =
            PHONE_EXT + this.wemloStaff[index].phone.replace(/[()-\s]/g, "");
      }
      this.wemloStaffName =
          this.wemloStaff[index].firstName +
          " " +
          this.wemloStaff[index].lastName;
      let obj = {
        name: this.wemloStaffName,
        toUserId: this.wemloStaff[index].userId,
        toUserType: "Wemlo Processor",
        phoneNumber: phoneNumber
      };

      if (this.wemloStaff[index].checked != false) {
        this.selectedContacts.push(obj);
        this.wemloStaff[index].checked = true;
      } else {
        this.selectedContacts = this.selectedContacts.filter(
            p => p.toUserId != userId
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  //select a single lender in lenderList
  public showDataForLenders(index, userId) {
    try {
      let phoneNumber =
          PHONE_EXT +
          this.accountExecutiveDetails[index].phone.replace(/[()-\s]/g, "");
      if (this.accountExecutiveDetails[index].accountExecutiveFirstName != "") {
        this.accountExecutiveName =
            this.accountExecutiveDetails[index].accountExecutiveFirstName +
            " " +
            this.accountExecutiveDetails[index].accountExecutiveLastName;
      } else
        this.accountExecutiveName = this.accountExecutiveDetails[
            index
            ].lenderName;

      let obj = {
        name: this.accountExecutiveName,
        toUserId: this.accountExecutiveDetails[index]._id,
        toUserType: "broker-lenders",
        phoneNumber: phoneNumber
      };

      if (this.accountExecutiveDetails[index].checked != false) {
        this.selectedContacts.push(obj);
        this.accountExecutiveDetails[index].checked = true;
      } else {
        this.selectedContacts = this.selectedContacts.filter(
            p => p.toUserId != userId
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  public showBorrowerContacts(index, userId) {
    try {
      let phoneNumber =
          PHONE_EXT + this.borrowerContacts[index].phone.replace(/[()-\s]/g, "");
      let name;
      if (this.borrowerContacts[index].type == "Title")
        name = this.borrowerContacts[index].companyName;
      else
        name =
            this.borrowerContacts[index].firstName +
            " " +
            this.borrowerContacts[index].lastName;

      let obj = {
        name: name,
        toUserId: this.borrowerContacts[index]._id,
        toUserType: "borrower-contact",
        phoneNumber: phoneNumber
      };

      if (this.borrowerContacts[index].checked != false) {
        this.selectedContacts.push(obj);
        this.borrowerContacts[index].checked = true;
      } else {
        this.selectedContacts = this.selectedContacts.filter(
            p => p.toUserId != userId
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async fetchBorrowerContacts() {
    try {
      if (this.toggleData == true) {
        this.showExistBorrowerContact = true;
        this.toggleData = false;
      } else {
        this.showExistBorrowerContact = false;
        this.toggleData = true;
      }
      let response = await Axios.post(
          BASE_API_URL + "common/fetchContactsWithSelectedTitles",
          {
            loanTxnId: this.$route.query.id
          });
      this.borrowerContacts = response.data.borrowerContacts;
      this.borrowerContacts.forEach(e => {
        this.selectedContacts.forEach(i => {
          if (i.toUserId == e._id) {
            e.checked = true;
          }
        });
      });
      this.contactTypeIsTitle = this.borrowerContacts.some(e => e.type === 'Title')
    } catch (error) {
      console.log(error);
    }
  }

  public async getWesignRequiredDocuments() {
    const loanTxnId = this.$route.query.id;

    this.brokerDisclosuresDocuments = [];
    this.additionalDocuments = [];
    this.approvedBrokerDisclosuresDocuments = [];
    this.approvedAdditionalDocuments = [];

    const result = await Axios.get(
        `${BASE_API_URL}disclosure/borrowerWesignRequiredDocuments`,
        {
          params: {loanTxnId}
        }
    );

    const brokerDisclosuresDocuments = result.data.filter(document => ['Broker Disclosure'].includes(document.type));
    this.everyBrokerDisclosureIsSigned = brokerDisclosuresDocuments.every(document => document.allFieldsSigned);

    if (this.everyBrokerDisclosureIsSigned) {
      this.approvedBrokerDisclosuresDocuments = brokerDisclosuresDocuments;
    } else {
      this.brokerDisclosuresDocuments = brokerDisclosuresDocuments;
    }

    result.data
        .filter(document => ['Additional Disclosure', 'Lender Disclosure'].includes(document.type))
        .forEach(document => {
          document.checked = false;

          (document.fileHistory.length == 0 || document.fileHistory[document.fileHistory.length - 1].status !== 'Approved')
              ? this.additionalDocuments.push(document)
              : _.get(document, 'batchToLender', null)
                  ? this.batchToLenderAdditionalDocuments.push(document)
                  : this.approvedAdditionalDocuments.push(document);
        });

    this.brokerDisclosuresDocumentsStatus =
        this.everyBrokerDisclosureIsSigned
            ? '--signed'
            : '--pending';
  }

  public getWesignStatusLabel(doc) {

    try {
      if (doc.allFieldsSigned) {
        return 'Signed';
      }
      if (!doc.allFieldsSigned) {
        return 'Pending signatures';
      }
    } catch (e) {
      return '';
    }

  }

  public toggleBrokerDisclosuresDocumentsVisibility() {
    this.showBrokerDisclosuresDocuments = !this.showBrokerDisclosuresDocuments;
  }

  public async downloadOriginalBrokerDisclosures($event) {
    this.downloadingBrokerDisclosure = true;
    $event.stopPropagation();

    const loanTxnId = this.borrower.pipelineData.loanTxnId;

    try {
      const response = await Axios.post(
          BASE_API_URL + "disclosure/downloadOriginalBrokerDisclosures",
          {loanTxnId},
          {responseType: "blob"}
      );

      saveAs(response.data, `${loanTxnId}-Original-Broker-Disclosures.zip`);

      this.downloadingBrokerDisclosure = false;
    } catch (error) {
      this.downloadingBrokerDisclosure = false;
    }
  }

  public async downloadSignedBrokerDisclosures($event) {
    this.downloadingBrokerDisclosure = true;
    $event.stopPropagation();

    const loanTxnId = this.borrower.pipelineData.loanTxnId;

    try {
      const response = await Axios.post(
          BASE_API_URL + "disclosure/downloadSignedBrokerDisclosures",
          {loanTxnId},
          {responseType: "blob"}
      );

      saveAs(response.data, `${loanTxnId}-Signed-Broker-Disclosures.zip`);

      this.downloadingBrokerDisclosure = false;
    } catch (error) {
      this.downloadingBrokerDisclosure = false;
    }
  }

  get getWetsignBorrowers() {

    const disclosures = this.everyBrokerDisclosureIsSigned
        ? this.approvedBrokerDisclosuresDocuments
        : this.brokerDisclosuresDocuments;

    const disclosuresWithWetsignatures = disclosures.filter(disclosure => _.get(disclosure, 'fileHistoryForBorrower', []).length);

    const borrowers = _.flatMap(disclosuresWithWetsignatures, disclosure => _.get(disclosure, 'fileHistoryForBorrower', []).map(item => _.get(item, 'borrowerId', '')));

    return borrowers.map(borrowerId => {
      const {
        firstName,
        lastName
      } = this.borrower.pipelineData.borrowerInfo.find(borrowerItem => _.get(borrowerItem, 'userId', '') === borrowerId);

      if (!firstName && !lastName) {
        return null;
      }

      return {
        borrowerId,
        name: `${firstName} ${lastName}`
      };
    }).filter(Boolean);
  }

  public async downloadWetsignDisclosures($event, borrower) {
    this.downloadingBrokerDisclosure = true;
    $event.stopPropagation();

    try {
      const loanTxnId = this.borrower.pipelineData.loanTxnId;

      const response = await Axios.post(
          BASE_API_URL + "disclosure/downloadSignedBrokerDisclosures",
          {loanTxnId, borrowerId: _.get(borrower, 'borrowerId', null)},
          {responseType: "blob"}
      );

      saveAs(response.data, `${loanTxnId}-${borrower.name}-Broker-Disclosures.zip`);

      this.downloadingBrokerDisclosure = false;
    } catch (error) {
      this.downloadingBrokerDisclosure = false;
    }
  }

  /*********************************************************************************************************************************************************************
   *                                                          Select Borrower for POS Flow                                                                             *
   *********************************************************************************************************************************************************************/

  public selectPosBorrower() {
    if (this.borrower) {
      // if (this.borrower.borrowerData.relationshipMapping.length == 1) {
      this.$router.push({
        path: this.$getCurrentUserTypePath("pos-questionnaire"),
        query: {
          loanTxnId: this.borrower.pipelineData.loanTxnId,
          borrowerId: this.borrower.pipelineData.borrowerInfo[
              this.borrower.pipelineData.primaryBorrowerIndex
              ].userId
        }
      });
      // } else {
      //   this.openBorrowerSelectionModal = true;
      // }
    }
  }

  public editBorrowerContact(Contact) {
    if (Contact.type === 'Title') {
      this.editModeForTitle = true;
    }
    try {
      this.isAction = true;
      this.borrowerContact = Contact;
    } catch (error) {
      console.log(error);
    }
  }

  public openFormsPage(doc = null) {
    this.$router.push(`/processor/current-urla?id=${this.$route.query.id}#Forms`);
  }

  async getEnumerationType() {
    const {data: {enumerationType}} = await Axios.post(
        BASE_API_URL + 'los/getEnumerationType',
        {loanTxnId: this.$route.query.id,}
    );

    return enumerationType;
  }

  public previewUploadFile(fileInfo) {
    let index = this.file.findIndex(f => f.name == fileInfo.name && f.size == fileInfo.size);
    if (index < 0 || this.isFileHasUploadError(this.file[index].name)) return;
    this.showAttachedFiles(index)
  }

  public isFileHasUploadError(fileName) {
    let fileInfo = this.file.find(f => f.name == fileName);
    if (!fileInfo) return;
    if (_.get(fileInfo, 'errorMessage')) return true;
  }

  setFileInput() {
    this.fileInput = document.documentElement.querySelector('.vue-file-agent .file-input');
  }

  public onPaperclipHandler() {
    if (!this.fileInput) {
      this.setFileInput();
    }

    this.fileInput?.click();
  }

  public setFileForDelete(file) {
    this.fileForDelete = file
  }

  public beforeUnloadHandler() {
    let data = JSON.parse(localStorage["state.WeMLO"]);
    if (data.removeLocalFiles.length > 0) {
      store.dispatch("removeLocalFiles", data.removeLocalFiles);
    }
  }

  public onDragToRowEnterHandler(e) {
      this.enterTargetRow = e.target;
      this.visibleInputId = e.currentTarget.id;
  }

  public onDragToRowLeaveHandler(e) {
    if (e.target === this.enterTargetRow) {
      this.visibleInputId = null;
    }
  }



  async mounted() {
    this.setFileInput();
    this.callingStatus = this.twilioActiveStatus;
    Device.on("ready", () => {
      this.$store.dispatch("setTwilioActiveStatus", "Ready");
    });
    Device.on("error", () => {
      this.$store.dispatch("setTwilioActiveStatus", "Cannot connect now.");
    });

    this.isMismo = (await this.getEnumerationType()) === 'mismo';

    await this.getBorrowerLoanDetails();
    await this.getContactList();
    await this.messageContacts();
    await this.getAdditionalStatus();
    await this.getWemloNumber();
    await this.getWesignRequiredDocuments();

    window.addEventListener("beforeunload", this.beforeUnloadHandler);
    EventBus.$on('file-for-delete', this.setFileForDelete)
  }

  /**************************************************************************************************
   *                                    IF FILES NOT LOADED IN DATABASE                              *
   **************************************************************************************************/
  async beforeDestroy() {
    Device.destroy();
    let data = JSON.parse(localStorage["state.WeMLO"]);
    if (data.removeLocalFiles.length > 0) {
      store.dispatch("removeLocalFiles", data.removeLocalFiles);
    }
    window.removeEventListener("beforeunload", this.beforeUnloadHandler);
    EventBus.$off('file-for-delete', this.setFileForDelete)
  }
}
