export class BorrowerContact {
    constructor(){
        this.loanTxnId = null;
        this.firstName = null;
        this.lastName = null;
        this.companyName = null;
        this.phone=null;
        this.phoneExtension=null;
        this.phoneType=null;
        this.emailType=null;
        this.email = null;
    }
    public loanTxnId: string;
    public firstName: string;
    public lastName: string;
    public companyName: string;
    public phone: number;
    public phoneExtension: string;
    public phoneType: string;
    public email: string;
    public emailType: string;


}