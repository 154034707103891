
















import { Component, Vue } from "vue-property-decorator";

@Component
export default class CheckCriticalFields extends Vue {
  public formTitle = '';
  public formMainMessage = '';
  
  public closeModal() {
    this.$modal.hide("modalForCheckCritical");
    this.$emit("hideModal");
  }

  public showModal(title, mainMessage) {
    this.formTitle = title;
    this.formMainMessage = mainMessage;
    this.$modal.show("modalForCheckCritical");
  }
}
