































import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class OverlayInput extends Vue {
  @Prop({ default: null }) readonly value: any;
  @Prop({ default: 'Filter' }) readonly label: string;
  @Prop({ default: 'icon-wemlo33' }) public icon: string;
  @Prop({ default: 'left-end' }) public placement: string;

  $refs!: {
    filterInput: any,
  };

  public canShowInput: boolean = false;

  public toggleInput() {
    this.canShowInput = !this.canShowInput;

    const filterInput = this.$refs.filterInput;

    if (this.canShowInput && filterInput) {
      setTimeout(() => {
        filterInput.focus();
      }, 100);
    }
  }

  public clearValue() {
    this.computedValue = '';
  }

  public get classes() {
    const color = this.computedValue ? 'orange' : 'blue'
    return {
      [this.icon]: !!this.icon,
      [`fg-color__${color}`]: true,
    }
  }

  public get computedValue() {
    return this.value
  }
  public set computedValue(val) {
    this.$emit('input', val)
  }
}
